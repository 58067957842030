import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLanguage } from 'src/utilities/getLanguage';

const browserLanguage = navigator.language.split('-')[0];

interface LanguageState {
  selectedLanguage: string ; 
}

const initialState: LanguageState = {
  selectedLanguage: getLanguage() || browserLanguage,
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
