import { createTheme, Shadows } from '@mui/material';
import { chattigo } from './Objects/Chattigo';
import { inceptia } from './Objects/Inceptia';
import { pelikanus } from './Objects/Pelikanus';
import { FontStack } from './fontFamily';

const themeObjects: any = {
  inceptia,
  chattigo,
  pelikanus,
};

export const createMuiThemeFromTheme = (themeName: any) => {
  const themeObject = themeObjects[themeName];
  return createThemeFromObject(themeObject);
};

const createThemeFromObject = (themeObject: any) => {
  return createTheme({
    palette: {
      primary: {
        main: themeObject.color.btnPrimary,
      },
      secondary: {
        main: themeObject.color.btnSecondary,
      },
      info: {
        main: inceptia.color.btnPrimary,
      },
    },
    typography: {
      fontFamily: FontStack,
    },
  });
};
