import { getTokenOauth } from "src/utilities/getTokenOauth";
import { BM_API_URL } from "../utils/api";
import { instanceLogin } from "../utils/axios.service.login";

export const loginWithMsftApiService = async () => {
  const login_url = `${BM_API_URL}/auth/tokens/`;
  const response = instanceLogin.post(
    login_url,
    { token: getTokenOauth() },
    { withCredentials: true }
  );
  return response;
};